.modal-content { background: var(--light-mode-bg); border-radius: 16px; border: none; }
.btn-close { padding: 0; width: 22px; height: 22px; background: url(../../images/input/close-icon.svg) no-repeat center center / 10px auto; border-radius: 50%; border: 1px solid $font-color; opacity: 1 !important; 
  [data-mode=dark] & {
    filter: brightness(0) invert(1) opacity(0.4);
  }
}
.modal-dialog { width: 850px; max-width: 94%; margin-left: auto; margin-right: auto;
    .modal-medium & { width: 632px;  }
    .modal-small & { width: 480px;  }
}

@media (max-width: 767px) {
  .modal-content { border-radius: 8px; border: none; }
}