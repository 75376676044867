.widget-title {
  margin-bottom: 14px;
  .icon {
    width: 24px;
    @include filter(invert(26%) sepia(93%) saturate(2578%) hue-rotate(218deg) brightness(94%) contrast(93%));
    [data-mode="dark"] & {
      @include filter(invert(88%) sepia(34%) saturate(4957%) hue-rotate(187deg) brightness(101%) contrast(118%));
    }
  }
}
