$form-check-input-checked-bg-image: none;
$form-check-radio-checked-bg-image: none;
$form-check-input-indeterminate-bg-image: none;
$form-switch-bg-image: none;
$form-switch-focus-bg-image: none;
$form-switch-checked-bg-image: none;
$form-select-indicator: none;
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;
$navbar-light-toggler-icon-bg: none;
$navbar-dark-toggler-icon-bg: none;
$accordion-button-icon: none;
$accordion-button-active-icon: none;
$carousel-control-prev-icon-bg: none;
$carousel-control-next-icon-bg: none;
$btn-close-bg: none;
$enable-negative-margins: true;

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
// Configuration
@import "~bootstrap/scss/functions";

$primary: #045de9;
$secondary: #50555d;
$success: #14b305;
$danger: #f14f4f;
$dark: #23272e;
$info: #e7f0ff;
$font-light: #757a82;
$text-muted: $font-light;

@import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
// @import "~bootstrap/scss/root";
@import "./bootstrap/_root.scss";
// @import "~bootstrap/scss/reboot";
@import "./bootstrap/_reboot.scss";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";

// Form Components
// @import "~bootstrap/scss/forms";
// @import "~bootstrap/scss/forms/labels";
// @import "~bootstrap/scss/forms/form-text";
// @import "~bootstrap/scss/forms/form-control";
// @import "~bootstrap/scss/forms/form-select";
// @import "~bootstrap/scss/forms/form-check";
// @import "~bootstrap/scss/forms/form-range";
// @import "~bootstrap/scss/forms/floating-labels";
// @import "~bootstrap/scss/forms/input-group";
// @import "~bootstrap/scss/forms/validation";

@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
@import "./bootstrap/_navbar.scss";
// @import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

// Helpers
// @import "~bootstrap/scss/helpers";
// @import "~bootstrap/scss/helpers/clearfix";
// @import "~bootstrap/scss/helpers/colored-links";
@import "~bootstrap/scss/helpers/ratio";
@import "~bootstrap/scss/helpers/position";
// @import "~bootstrap/scss/helpers/stacks";
@import "~bootstrap/scss/helpers/visually-hidden";
// @import "~bootstrap/scss/helpers/stretched-link";
// @import "~bootstrap/scss/helpers/text-truncation";
// @import "~bootstrap/scss/helpers/vr";

// Utilities
@import "~bootstrap/scss/utilities/api";


@import "./fonts";
@import "./_variables.scss";
@import "./_mixins.scss";
@import "./common.scss";
@import "./_utilities.scss";

@import "./components/table.scss";
@import "./components/accordion.scss";
// @import "./components/slider.scss";
// @import "./components/form.scss";
@import "./components/badge.scss";
@import "./components/modal.scss";
@import "./components/tab.scss";
@import "./components/input.scss";

// @import "./pages/match.scss";

@import "./client-components/downloadApp.scss";
@import "./client-components/ads.scss";
@import "./client-components/toast.scss";
@import "./client-components/header-search.scss";
@import "./client-components/mobile-menu.scss";
@import "./client-components/twitter.scss";
@import "./client-components/link-preview.scss";
@import "./client-components/widgets/trending-news.scss";
@import "./client-components/widgets/widgets.scss";
