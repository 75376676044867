.scroll-list { overflow: auto; overflow-y: hidden; }
.nav-pills { text-align: center;
    .nav-link { padding: 7px; border-radius: 2em; background: var(--theme-bg); font-size: 13px; line-height: 18px; font-weight: 700; color: inherit;
        &[aria-selected="true"] { background: $theme-color; }
    }
    &.equal-width-nav { 
        .nav-item { flex: 1; -webkit-flex: 1; }
    }
} 
.simple-tab { text-align: center; border-bottom: 1px solid var(--border-medium);
    .nav-item { flex-grow: 1; }
    .nav-link { margin: 0; padding: 12px 7px; font-size: 13px; line-height: 18px; color: inherit; position: relative;
        &.active { background: transparent; color: inherit; 
            &::after { opacity: 1; }
        }
        &::after { @extend %after-before; width: 100%; height: 6px; bottom: 0; left: 0; background: $theme-color; border-radius: 4px 4px  0px 0px; opacity: 0; }
    }
}
