@media (max-width: 767px) {
  .mobileMenu { z-index: 9; @include box-shadow(2px 0px 8px rgba(0,0,0,0.1));
    &.scrollTrigger { @include transform(translateY(100%)); @include transition($common-transition); 
      &.fixed { @include transform(translateY(0%)) }
    }
    // li { min-width: 66px; }
    svg { display: block; margin: 0px auto 2px; width: 26px; height: 26px; }
    a, button { font-size: 11px;
      &.active { background: var(--theme-light); color: var(--theme-medium2);
        path,polygon { fill: var(--theme-medium2); }
      }
      path,polygon { fill: var(--font-color); }
     }
     a:hover { color: var(--theme-medium2);
      path,polygon { fill: var(--theme-medium2); }
    }
  }
}

// @media (max-width: 350px) {
//   .mobileMenu {
//     li { min-width: 60px;
//     }
//   }
// }