.table-responsive,
.table-scroller { margin-bottom: 16px; overflow-x: auto; -webkit-overflow-scrolling: touch; }
.table-responsive>.table-responsive{margin-bottom: 0;}
table { margin: 0px; width: 100%; border-spacing: 0 4px; border-collapse: separate; border: none;
  th, td { padding: 3px 14px; height: 42px;
    &:first-child { border-top-left-radius: 8px; border-bottom-left-radius: 8px; }
    &:last-child { border-top-right-radius: 8px; border-bottom-right-radius: 8px; }
  }
  th { background: $theme-color; color: #fff; text-transform: capitalize; }
  td { background: var(--light-mode-bg); }
  .highlight td { background: var(--theme-light-medium);}
  .icon-img { margin-right: 10px; display: inline-block; width: 36px; height: 36px; background: #fff; border-radius: 10px; overflow: hidden; vertical-align: middle; }
  &.numbered {
    th:first-child { width: 51px; }
  }
  &.bordered { border-spacing: 0;
    th, td { border-radius: 0; }
    td { border-bottom: 1px solid var(--theme-bg); }
  }
  &.dark {
    tbody {
      td {
        background-color: var(--theme-bg);
      }
    }
  }
}
/***** Responsive CSS Start ******/

@media (max-width: 991px) {
  table { margin: -4px 0px 4px; width: 100%; border-spacing: 0 6px; border-collapse: separate;
    th, td { padding: 4px 10px; height: 40px;
      &:first-child { border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
      &:last-child { border-top-right-radius: 4px; border-bottom-right-radius: 4px; }
    }
    .icon-img { width: 24px; height: 24px; border-radius: 4px; }
  }
}

@media (max-width: 575px) {
  .table-responsive,
  .table-scroller { margin-bottom: 12px; }
  table { margin: -3px 0px 3px; width: 100%; border-spacing: 0 6px; border-collapse: separate;
    th, td { padding: 5px 6px; height: 30px;
      &:first-child { border-top-left-radius: 3px; border-bottom-left-radius: 3px; padding-left: 10px; }
      &:last-child { border-top-right-radius: 3px; border-bottom-right-radius: 3px; padding-right: 10px; }
    }
    .icon-img { width: 23px; height: 23px; border-radius: 3px; }
  }
}

